import React, { useState, useEffect } from 'react';
import { List, Card, Typography, Spin, notification, Button, Modal } from 'antd';
import { fetchNotes, deleteNotes } from "../../../api/services/user-main/notes/user-note-service";
import { ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import './NoteViewComponent.css';

const NoteViewComponent = () => {
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [nextToken, setNextToken] = useState(null);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [noteToDelete, setNoteToDelete] = useState(null);

    const fetchNotesData = async (lastKey = null, reset = false) => {
        setLoading(true);
        try {
            const data = await fetchNotes(lastKey);
            setNotes(prevNotes => {
                const uniqueNotes = data.filter(
                    newNote => !prevNotes.some(note => note._id === newNote._id)
                );
                return reset ? uniqueNotes : [...prevNotes, ...uniqueNotes];
            });
            setNextToken(data.nextKey || null);
        } catch (error) {
            setError('An error ocurred while notes are loading..');
            notification.error({ message: 'An error ocurred while notes are loading..' });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (noteToDelete) {
            try {
                await deleteNotes(noteToDelete._id);
                setNotes(prevNotes => prevNotes.filter(note => note._id !== noteToDelete._id));
                setDeleteConfirmationVisible(false);
                setNoteToDelete(null);
                notification.success({ message: 'Not deleted successfully' });
            } catch (error) {
                notification.error({ message: 'An error occurred while deleting notes..' });
            }
        }
    };

    const showDeleteConfirmation = (note) => {
        setNoteToDelete(note);
        setDeleteConfirmationVisible(true);
    };

    const handleCancelDelete = () => {
        setDeleteConfirmationVisible(false);
        setNoteToDelete(null);
    };

    useEffect(() => {
        fetchNotesData(null, true);
    }, []);

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target.scrollingElement;
        if (scrollHeight - scrollTop === clientHeight && nextToken && !loading) {
            fetchNotesData(nextToken);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [nextToken, loading]);

    const handleRefresh = () => {
        fetchNotesData(null, true);
    };

    return (
        <div className="note-view-container">
            {error && <Typography.Text type="danger">{error}</Typography.Text>}

            <div className="note-view-header">
                <Button icon={<ReloadOutlined />} onClick={handleRefresh}>Refresh</Button>
            </div>

            <List
                dataSource={notes}
                renderItem={note => (
                    <List.Item key={note._id}>
                        <Card
                            className="note-card"
                            style={{
                                backgroundImage: 'url("/user-background/notes/note-view-background.png")',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <Typography.Text className="note-content">
                                {note.content}
                            </Typography.Text>
                            <div className="note-footer">
                                <Typography.Text className="note-author">Author: {note.name}</Typography.Text>
                                <br />
                                <Typography.Text className="note-date">{new Date(note.created_at).toLocaleString()}</Typography.Text>
                            </div>
                        </Card>
                        <div className="note-delete-button">
                            <Button
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => showDeleteConfirmation(note)}
                            >
                                Delete
                            </Button>
                        </div>
                    </List.Item>
                )}
            />

            {loading && <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />}

            <Modal
                title="Are you sure you want to delete the note?"
                visible={deleteConfirmationVisible}
                onOk={handleDelete}
                onCancel={handleCancelDelete}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>This action cannot be undone!</p>
            </Modal>
        </div>
    );
};

export default NoteViewComponent;


//export const API_URL = 'http://localhost:8004';
export const API_URL = 'https://app.memory-box.co'

export const initialRequest = `${API_URL}/api/v1/qr/`;
export const photoUpload = API_URL+'/api/v1/frontend/upload/photo/'
export const videoUpload = API_URL+'/api/v1/frontend/upload/video/'
export const audioUpload = API_URL+'/api/v1/frontend/upload/audio/'
export const noteUpload = API_URL+'/api/v1/frontend/note/upload/'

export const noteRead = API_URL+'/api/v1/user/notes/'

export const photoRead = API_URL+'/api/v1/user/photos/'
export const login = API_URL+'/api/v1/auth/user/login/'

export const deleteFile = API_URL+'/api/v1/user/file/'

export const deleteNotesApi = API_URL+'/api/v1/user/note/'
export const getPhoto = API_URL+'/api/v1/user/photo/'

export const getNotes = API_URL+'/api/v1/user/notes/'

export const getVideo = API_URL+'/api/v1/user/video/'

export const getVoices = API_URL+'/api/v1/user/voice/'

export const getProfileData = API_URL+'/api/v1/user/info/'

export const getNotesCount = API_URL+'/api/v1/user/objects-count/'

export const updatePassword = API_URL+'/api/v1/user/update/password/'
export const updateUsername = API_URL+'/api/v1/user/update/username/'

export const updateProfilePicture = API_URL+'/api/v1/user/update/profile-picture/'

export const downloadMedia = API_URL+'/api/v1/user/download/'

export const updateWelcomeMessage = API_URL+'/api/v1/user/update-welcome-message/'

export const updateInstructions = API_URL+'/api/v1/user/update-instructions/'

export const updateQRStatus = API_URL+'/api/v1/qr/lock/status/edit/'







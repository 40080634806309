const translations = {
    en: {
        addPicture: "Add Photo",
        addVideo: "Add Video",
        addAudioNote: "Add Voice Record",
        addNote: "Add Note",
        uploadPhotos: "Upload Photos",
        startUpload: "Start Uploading",
        selectPhotos: "Select Photos",
        uploadVideos: "Upload Videos",
        selectVideos: "Select Videos",
        uploadAudios: "Upload Voice Records",
        selectAudios: "Select Voice Records",
        pleaseUploadAtLeastOnePhoto: "Please upload at least one image file",
        pleaseUploadAtLeastOneVideo: "Please upload at least one video file",
        pleaseUploadAtLeastOneAudio: "Please upload at least one audio file",
        onlyImageFilesAllowed: "Only image files allowed",
        onlyVideoFilesAllowed: "Only video files allowed",
        onlyAudioFilesAllowed: "Only audio files allowed",
        photosUploadedSuccessfully: "Photos uploaded successfully",
        videosUploadedSuccessfully: "Videos uploaded successfully",
        audiosUploadedSuccessfully: "Audio files uploaded successfully",
        photoUploadFailed: "Photo upload failed",
        videoUploadFailed: "Video upload failed",
        audioUploadFailed: "Audio file upload failed",
        pleaseWriteANote: "Please write some note",
        noteUploadedSuccessfully: "Note upload successfully",
        noteUploadFailed: "Note upload failed",
        writeYourNote: "Write your note",
        sendNote: "Send Note",
        confirmation: "Confirm",
        areYouSureToSendNote: "Are you sure the send the note?",
        edit: "Edit",
        confirm: "Submit",
        uploading: "Loading..",
        cancel: "Close",
        welcomeMessage: "Welcome, please change this message over the events section!",
        instructions: "Share with us the pictures and videos you took at the event so we can access them forever :)",
        maxTenPhotos: "You can select up to 10 photos.",
        maxOneVideo: "You can only select one video at a time.",
        startUploadInstructions: "You can start the upload after selecting the photos.",
        startUploadVideoInstructions: "You can start the upload after selecting the video."



    },
    mk: {
        addPicture: "Додај слика",
        addVideo: "Додај видео",
        addAudioNote: "Додај аудио белешка",
        addNote: "Додај белешка",
        uploadPhotos: "Поставете фотографии",
        startUpload: "Започни со поставување",
        selectPhotos: "Избери фотографии",
        uploadVideos: "Поставете видеа",
        selectVideos: "Избери видеа",
        uploadAudios: "Поставете аудио белешки",
        selectAudios: "Избери аудио белешки",
        pleaseUploadAtLeastOnePhoto: "Ве молиме поставете најмалку една фотографија",
        pleaseUploadAtLeastOneVideo: "Ве молиме поставете најмалку едно видео",
        pleaseUploadAtLeastOneAudio: "Ве молиме поставете најмалку една аудио белешка",
        onlyImageFilesAllowed: "Дозволени се само слики",
        onlyVideoFilesAllowed: "Дозволени се само видеа",
        onlyAudioFilesAllowed: "Дозволени се само аудио белешки",
        photosUploadedSuccessfully: "Фотографиите беа успешно поставени",
        videosUploadedSuccessfully: "Видеата беа успешно поставени",
        audiosUploadedSuccessfully: "Аудио белешките беа успешно поставени",
        photoUploadFailed: "Поставувањето на фотографиите не успеа",
        videoUploadFailed: "Поставувањето на видеата не успеа",
        audioUploadFailed: "Поставувањето на аудио белешките не успеа",
        pleaseWriteANote: "Ве молиме напишете белешка",
        noteUploadedSuccessfully: "Белешката беше успешно поставена",
        noteUploadFailed: "Поставувањето на белешката не успеа",
        writeYourNote: "Напишете ја вашата белешка",
        sendNote: "Испрати белешка",
        confirmation: "Потврда",
        areYouSureToSendNote: "Дали сте сигурни дека сакате да ја испратите белешката?",
        edit: "Уредете",
        confirm: "Потврди",
        uploading: "Поставување",
        cancel: "Затвори",
        welcomeMessage: "Welcome to our wedding, please share that you take photo, videos. If you want, you can drop some note and share your wishes",
        messageName: "Име на лицето кое ја напишало белешката",
        instructions: "Можете да споделувате фотографии и видеа 48 часа. На тој начин ќе имате пристап до нив засекогаш.",

    }
};

export default translations;

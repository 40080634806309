import axios from 'axios';
import { videoUpload } from "../../../config/api-config";

export const videoUploadApi = async (formData, config) => {
    try {
        const response = await axios.post(videoUpload, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...config,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

import React, { useState } from 'react';
import { Upload, Button, message, Typography, Input } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import translations from "../../../config/translation/translation-config";
import { photoUploadApi } from "../../../api/services/qr/photo/photo-upload-call";

const { Text } = Typography;

const PhotoUpload = ({ language, objectStorage }) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [fullName, setFullName] = useState("");

    const handleUpload = async () => {
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files', file.originFileObj);
        });

        formData.append('path_id', objectStorage);
        formData.append('username', fullName);

        setUploading(true);

        try {
            await photoUploadApi(formData);
            message.success(translations[language].photosUploadedSuccessfully);
            setFileList([]);
        } catch (error) {
            message.error(translations[language].photoUploadFailed);
        } finally {
            setUploading(false);
        }
    };

    const beforeUpload = (file) => {
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/heic', 'image/heif'];
        const isImage = allowedTypes.includes(file.type);
        if (!isImage) {
            message.error(translations[language].onlyImageFilesAllowed);
            return Upload.LIST_IGNORE;
        }
        if (fileList.length >= 10) {
            message.warning(translations[language].maxTenPhotos);
            return Upload.LIST_IGNORE;
        }
        return false;
    };

    const handleChange = (info) => {
        let newFileList = [...info.fileList];

        if (newFileList.length + fileList.length > 10) {
            newFileList = newFileList.slice(0, 10 - fileList.length);
            message.warning(translations[language].maxTenPhotos);
        }

        setFileList(newFileList);
    };

    const handleRemove = (file) => {
        setFileList(fileList.filter(f => f.uid !== file.uid));
        message.success(translations[language].photoRemovedSuccessfully);
    };

    return (
        <div>
            <Input
                placeholder="Please write your full name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                style={{ marginBottom: 16 }}
            />
            <Upload
                accept="image/*"
                multiple
                beforeUpload={beforeUpload}
                fileList={fileList}
                onChange={handleChange}
                onRemove={handleRemove} // Handle file removal
                showUploadList={{
                    showRemoveIcon: true, // Show remove icon
                    removeIcon: <DeleteOutlined />, // Customize remove icon if needed
                }}
            >
                <Button icon={<UploadOutlined />}>{translations[language].uploadPhotos}</Button>
            </Upload>
            <Text type="secondary">
                {translations[language].maxTenPhotos} - {translations[language].startUploadInstructions}
            </Text>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0 || fullName.trim() === ""}
                loading={uploading}
                style={{ marginTop: 16 }}
            >
                {uploading ? translations[language].uploading : translations[language].startUpload}
            </Button>
        </div>
    );
};

export default PhotoUpload;

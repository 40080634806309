import React, { useState, useRef } from 'react';
import { Button, message, Input } from 'antd';
import { AudioOutlined, ReloadOutlined } from '@ant-design/icons';
import ReactAudioPlayer from 'react-audio-player';
import { audioUploadApi } from "../../../api/services/qr/audio/audio-upload-call";

const VoiceUpload = ({ objectStorage }) => {
    const [userName, setUserName] = useState("");
    const [uploading, setUploading] = useState(false);
    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioURL, setAudioURL] = useState("");
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };
                mediaRecorderRef.current.onstop = () => {

                    const blob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
                    setAudioBlob(blob);
                    setAudioURL(URL.createObjectURL(blob));
                    audioChunksRef.current = [];
                };
                mediaRecorderRef.current.start();
                setRecording(true);
            })
            .catch(error => {
                message.error('Failed to start recording.');
            });
    };


    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    const handleUpload = async () => {
        if (!audioBlob || !userName || !objectStorage) {
            message.error('Please record audio, enter your name, and ensure object storage is set.');
            return;
        }

        setUploading(true);

        const formData = new FormData();
        formData.append('username', userName);
        formData.append('path_id', objectStorage);
        formData.append('files', audioBlob, `audio-${new Date().toISOString()}.wav`);

        try {
            await audioUploadApi(formData);
            message.success('Audio uploaded successfully.');
            setAudioBlob(null);
            setAudioURL("");
            setUserName("");
        } catch (error) {
            message.error('Failed to upload audio.');
        } finally {
            setUploading(false);
        }
    };

    const handleRetake = () => {
        setAudioBlob(null);
        setAudioURL("");
        setRecording(false);
    };

    return (
        <div style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Input
                placeholder="Enter your name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                style={{ marginBottom: 10 }}
            />
            <Button
                icon={<AudioOutlined />}
                onClick={recording ? stopRecording : startRecording}
                type="primary"
                style={{ marginBottom: 10, width: '100%' }}
            >
                {recording ? 'Stop Recording' : 'Start Recording'}
            </Button>
            {audioBlob && (
                <>
                    <ReactAudioPlayer
                        src={audioURL}
                        controls
                        style={{ marginBottom: 10, width: '100%' }}
                    />
                    <Button
                        type="default"
                        onClick={handleRetake}
                        style={{ width: '100%', marginBottom: 10 }}
                    >
                        <ReloadOutlined /> Retake
                    </Button>
                </>
            )}
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={!audioBlob || !userName || !objectStorage}
                loading={uploading}
                style={{ width: '100%' }}
            >
                {uploading ? 'Uploading...' : 'Upload'}
            </Button>
        </div>
    );
};

export default VoiceUpload;

import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, Card, Modal } from 'antd';
import { PictureOutlined, VideoCameraOutlined, AudioOutlined, FileTextOutlined } from '@ant-design/icons';
import './event-main-screen.css';
import translations from "../../../config/translation/translation-config";
import PhotoUpload from "../photo-upload/photo-upload";
import VideoUpload from "../video-upload/video-upload";
import NoteUpload from "../note/note-upload";
import VoiceUpload from "../voice-upload/VoiceUpload";

const { Title, Paragraph, Text } = Typography;

const EventMainScreen = () => {
    const [language, setLanguage] = useState('en');
    const [qrData, setQrData] = useState({});
    const [isPhotoModalVisible, setIsPhotoModalVisible] = useState(false);
    const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
    const [isVoiceModalVisible, setIsVoiceModalVisible] = useState(false);
    const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);

    const [welcomeMessage, setWelcomeMessage] = useState(translations[language].welcomeMessage);
    const [instructions, setInstructions] = useState(translations[language].instructions);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('qrData'));
        if (data) {
            setQrData(data);
            if (data.language) {
                setLanguage(data.language);
            }
            if (data.welcomeMessage) {
                setWelcomeMessage(data.welcomeMessage);
            }
            if (data.instructions) {
                setInstructions(data.instructions);
            }
        }
    }, []);

    const showPhotoModal = () => {
        setIsPhotoModalVisible(true);
    };

    const handlePhotoCancel = () => {
        setIsPhotoModalVisible(false);
    };

    const showVideoModal = () => {
        setIsVideoModalVisible(true);
    };

    const handleVideoCancel = () => {
        setIsVideoModalVisible(false);
    };

    const showVoiceModal = () => {
        setIsVoiceModalVisible(true);
    };

    const handleVoiceCancel = () => {
        setIsVoiceModalVisible(false);
    };

    const showNoteModal = () => {
        setIsNoteModalVisible(true);
    };

    const handleNoteCancel = () => {
        setIsNoteModalVisible(false);
    };

    return (
        <div className="main-container">
            <Title level={2} style={{ textAlign: 'center', marginBottom: '20px', color: 'black' }}>
                {welcomeMessage}
            </Title>
            <Paragraph style={{ textAlign: 'center', marginBottom: '40px', color: 'black' }}>
                {instructions}
            </Paragraph>
            <Row gutter={[16, 16]} justify="center">
                <Col xs={24} sm={12} md={6}>
                    <Card className="custom-card" style={{ backgroundColor: '#E0F7FA' }}>
                        <Button
                            type="primary"
                            icon={<PictureOutlined />}
                            size="large"
                            block
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#00796B', borderColor: '#00796B' }}
                            onClick={showPhotoModal}
                        >
                            {translations[language].addPicture}
                        </Button>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card className="custom-card" style={{ backgroundColor: '#FFF3E0' }}>
                        <Button
                            type="primary"
                            icon={<VideoCameraOutlined />}
                            size="large"
                            block
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F57C00', borderColor: '#F57C00' }}
                            onClick={showVideoModal}
                        >
                            {translations[language].addVideo}
                        </Button>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card className="custom-card" style={{ backgroundColor: '#EDE7F6' }}>
                        <Button
                            type="primary"
                            icon={<AudioOutlined />}
                            size="large"
                            block
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#5E35B1', borderColor: '#5E35B1' }}
                            onClick={showVoiceModal}
                        >
                            {translations[language].addAudioNote}
                        </Button>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card className="custom-card" style={{ backgroundColor: '#FFF8E1' }}>
                        <Button
                            type="primary"
                            icon={<FileTextOutlined />}
                            size="large"
                            block
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FBC02D', borderColor: '#FBC02D' }}
                            onClick={showNoteModal}
                        >
                            {translations[language].addNote}
                        </Button>
                    </Card>
                </Col>
            </Row>
            <div className="footer">
                <Text type="secondary">powered by dp solutions®</Text>
            </div>

            <Modal
                title={translations[language].uploadPhotos}
                open={isPhotoModalVisible}
                footer={null}
                onCancel={handlePhotoCancel}
            >
                <PhotoUpload language={language} objectStorage={qrData.path_id} />
            </Modal>

            <Modal
                title={translations[language].uploadVideos}
                open={isVideoModalVisible}
                footer={null}
                onCancel={handleVideoCancel}
            >
                <VideoUpload language={language} objectStorage={qrData.path_id} />
            </Modal>

            <Modal
                title={translations[language].uploadAudios}
                open={isVoiceModalVisible}
                footer={null}
                onCancel={handleVoiceCancel}
            >
                <VoiceUpload language={language} objectStorage={qrData.path_id} />
            </Modal>

            <Modal
                title={translations[language].addNote}
                open={isNoteModalVisible}
                footer={null}
                onCancel={handleNoteCancel}
                key={isNoteModalVisible}
            >
                <NoteUpload language={language} visible={isNoteModalVisible} onClose={handleNoteCancel} />
            </Modal>
        </div>
    );
};

export default EventMainScreen;

import React, { useState } from 'react';
import { Box, BottomNavigation, BottomNavigationAction, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { PhotoCamera, VideoLibrary, Menu as MenuIcon } from '@mui/icons-material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { useNavigate, Outlet } from 'react-router-dom';

const MainPage = () => {
    const [value, setValue] = useState('photos');
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNavigation = (page) => {
        setValue(page);
        navigate(`/user-main/${page}`);
    };

    const handleMenuClick = (route) => {
        handleMenuClose();
        navigate(route);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        navigate('/login');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: '#f2f2f2' }}>
            <Box sx={{ flexGrow: 1, p: 3, pb: isSmallScreen ? '72px' : '56px' }}>
                <Outlet />
            </Box>

            <BottomNavigation
                value={value}
                onChange={(event, newValue) => handleNavigation(newValue)}
                showLabels={!isSmallScreen}
                sx={{
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    bgcolor: '#ffffff',
                    boxShadow: 3,
                    display: 'flex',
                    justifyContent: 'space-around',
                    px: 1,
                    height: isSmallScreen ? 50 : 70,  // Yükseklik küçük ekranlar için ayarlandı
                    paddingBottom: 'calc(env(safe-area-inset-bottom) + 10px)',  // Safe area boyutu artırıldı
                }}
            >
                <BottomNavigationAction
                    label="Photos"
                    value="photos"
                    icon={<PhotoCamera sx={{ fontSize: isSmallScreen ? 18 : 24 }} />}  // İkon boyutu ayarlandı
                    sx={{ minWidth: 'auto', flex: 1, maxWidth: isSmallScreen ? 80 : 'none' }}  // Küçük ekranlar için genişlik sınırı eklendi
                />
                <BottomNavigationAction
                    label="Videos"
                    value="videos"
                    icon={<VideoLibrary sx={{ fontSize: isSmallScreen ? 18 : 24 }} />}
                    sx={{ minWidth: 'auto', flex: 1, maxWidth: isSmallScreen ? 80 : 'none' }}
                />
                <BottomNavigationAction
                    label="Notes"
                    value="notes"
                    icon={<NoteAltIcon sx={{ fontSize: isSmallScreen ? 18 : 24 }} />}
                    sx={{ minWidth: 'auto', flex: 1, maxWidth: isSmallScreen ? 80 : 'none' }}
                />
                <BottomNavigationAction
                    label="Voice"
                    value="voiceRecords"
                    icon={<KeyboardVoiceIcon sx={{ fontSize: isSmallScreen ? 18 : 24 }} />}
                    sx={{ minWidth: 'auto', flex: 1, maxWidth: isSmallScreen ? 80 : 'none' }}
                />

                <IconButton
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    sx={{
                        color: 'primary.main',
                        ml: 1,
                        ...(isSmallScreen && { fontSize: 'small' }),  // Küçük ekranlar için düğme boyutu
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </BottomNavigation>


            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{
                    mt: isSmallScreen ? -5 : 0,
                    minWidth: '80vw',
                    maxWidth: '90vw',
                }}
            >
                <MenuItem onClick={() => handleMenuClick('/user-main/profile')}>Profile</MenuItem>
                <MenuItem onClick={() => handleMenuClick('/user-main/events')}>Events</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </Box>
    );
};

export default MainPage;

import axios from 'axios';
import { login } from "../../../config/api-config";

export const loginApiCall = async (loginData) => {
    try {
        const formData = new FormData();
        formData.append('username', loginData.username);
        formData.append('password', loginData.password);

        const response = await axios.post(login, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthRoute = ({ children }) => {
    const navigate = useNavigate();
    const isAuthenticated = !!localStorage.getItem('authToken'); // Oturum durumunu kontrol et

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login'); // Oturum açılmamışsa login sayfasına yönlendir
        }
    }, [isAuthenticated, navigate]);

    return isAuthenticated ? children : null;
};



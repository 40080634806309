import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, message } from 'antd';
import translations from "../../../config/translation/translation-config";
import { noteUploadApi } from "../../../api/services/qr/note/note-upload-call";

const { TextArea } = Input;

const NoteUpload = ({ language, visible, onClose }) => {
    const [note, setNote] = useState('');
    const [name, setName] = useState('');
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);

    useEffect(() => {
        console.log('Modal visibility changed:', visible);
    }, [visible]);

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleSendNote = () => {
        if (note.trim() === '' || name.trim() === '') {
            message.error(translations[language].pleaseWriteANoteAndName);
            return;
        }
        setIsConfirmVisible(true);
    };

    const handleConfirmSend = async () => {
        try {
            const noteData = {
                content: note,
                name: name,
                event_owner: JSON.parse(localStorage.getItem('qrData')).event_owner,
            };
            await noteUploadApi(noteData);
            message.success(translations[language].noteUploadedSuccessfully);
            setNote('');
            setName('');
            setIsConfirmVisible(false);
            onClose();
        } catch (error) {
            message.error(translations[language].noteUploadFailed);
        }
    };

    const handleCancelSend = () => {
        setIsConfirmVisible(false);
    };

    return (
        <>
            <Modal
                title={translations[language].addNote}
                open={visible}
                onCancel={() => {
                    console.log('Modal cancel button clicked');
                    onClose();
                }}
                footer={[
                    <Button key="cancel" onClick={() => {
                        console.log('Cancel button clicked');
                        onClose();
                    }}>
                        {translations[language].cancel}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleSendNote}
                    >
                        {translations[language].sendNote}
                    </Button>,
                ]}
            >
                <Input
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Please write your name.."
                    style={{ marginBottom: '16px' }}
                />
                <TextArea
                    rows={6}
                    value={note}
                    onChange={handleNoteChange}
                    placeholder={translations[language].writeYourNote}
                />
            </Modal>

            <Modal
                title={translations[language].confirmation}
                open={isConfirmVisible}
                onCancel={handleCancelSend}
                footer={[
                    <Button key="back" onClick={handleCancelSend}>
                        {translations[language].edit}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleConfirmSend}
                    >
                        {translations[language].confirm}
                    </Button>,
                ]}
            >
                <p>{translations[language].areYouSureToSendNote}</p>
            </Modal>
        </>
    );
};

export default NoteUpload;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import NotFound from "./404/not-found";
import { fetchQRCodeData } from "./api/services/backend-calls";
import EventMainScreen from "./qr/event/event-main/event-main-screen";
import Login from "./user-screen/pages/login/login";
import {AuthRoute} from "./auth/auth";
import Photos from "./user-screen/components/photo/photo-view-component";
import MainPage from "./user-screen/pages/user-main/user-main";
import Videos from "./user-screen/components/video/video-view-component";
import NoteViewComponent from "./user-screen/components/notes/note-view-component";
import VoiceViewComponent from "./user-screen/components/voice/VoiceViewComponent";
import ProfilePage from "./user-screen/components/settings/profile/ProfilePage";
import EventsPage from "./user-screen/components/settings/event/EventPage";
import Logo from "./user-screen/components/logo/UserMainLogo";

const App = () => {
    return (
        <Router>
            <AppRoutes />
        </Router>
    );
};

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const qrId = location.pathname.split('/')[1];
                const qrData = await fetchQRCodeData(qrId);
                if (qrData.is_lock) {
                    navigate('/404');
                } else {
                    localStorage.setItem('qrData', JSON.stringify(qrData));
                    navigate('/event-main-screen');
                }
            } catch (error) {
                navigate('/404');
            }
        };
        fetchData();
    }, [navigate, location]);

    return null;
};

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const qrData = JSON.parse(localStorage.getItem('qrData'));

    useEffect(() => {
        if (!qrData || qrData.is_lock) {
            navigate('/404');
        }
    }, [navigate, qrData]);

    return qrData && !qrData.is_lock ? children : null;
};

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/event-main-screen" element={
                <ProtectedRoute>
                    <EventMainScreen />
                </ProtectedRoute>
            } />
            <Route path="/user-main" element={
                <AuthRoute>
                    <MainPage />
                </AuthRoute>
            }>
                <Route index element={<Photos />} />
                <Route path="photos" element={<Photos />} />
                <Route path="videos" element={<Videos/>} />
                <Route path="notes" element={<NoteViewComponent/>} />
                <Route path="voiceRecords" element={<VoiceViewComponent/>} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="events" element={<EventsPage />} />
            </Route>
            <Route path="/user-main/photos" element={
                <AuthRoute>
                    <Photos />
                </AuthRoute>
            } />
            <Route path="/user-main/videos" element={
                <AuthRoute>
                    <Videos />
                </AuthRoute>
            } />
            <Route path="/user-main/notes" element={
                <AuthRoute>
                    <NoteViewComponent />
                </AuthRoute>
            } />
            <Route path="/user-main/voices" element={
                <AuthRoute>
                    <NoteViewComponent />
                </AuthRoute>
            } />
            <Route path="/user-main/profile" element={
                <AuthRoute>
                    <ProfilePage />
                </AuthRoute>
            } />
            <Route path="/user-main/events" element={
                <AuthRoute>
                    <EventsPage />
                </AuthRoute>
            } />
            <Route path="/404" element={<NotFound />} />
            <Route path="/*" element={<Home />} />
            <Route path="/login" element={<Login />} />
        </Routes>
    );
};

export default App;

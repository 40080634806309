import React, { useState, useEffect, useCallback } from 'react';
import { List, Typography, Spin, notification, Button, Modal } from 'antd';
import { ReloadOutlined, DeleteOutlined, PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import ReactAudioPlayer from 'react-audio-player';
import { fetchVoices } from "../../../api/services/user-main/voice/user-voice-service";
import { deleteFileApi } from "../../../api/services/user-main/photo/user-photo-service";

const VoiceViewComponent = () => {
    const [voiceRecords, setVoiceRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [nextToken, setNextToken] = useState(null);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [playingUrl, setPlayingUrl] = useState(null);

    const fetchVoiceRecordsData = async (lastKey = null, reset = false) => {
        setLoading(true);
        try {
            const data = await fetchVoices(lastKey);
            setVoiceRecords(prevRecords => {
                const uniqueRecords = data.newFiles.filter(
                    newRecord => !prevRecords.some(record => record.url === newRecord.url)
                );
                return reset ? uniqueRecords : [...prevRecords, ...uniqueRecords];
            });
            setNextToken(data.nextKey || null);
        } catch (error) {
            setError('An error occurred while loading voice records...');
            notification.error({ message: 'An error occurred while loading voice records...' });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (recordToDelete) {
            try {
                await deleteFileApi(recordToDelete.url);
                setVoiceRecords(prevRecords => prevRecords.filter(record => record.url !== recordToDelete.url));
                setDeleteConfirmationVisible(false);
                setRecordToDelete(null);
                notification.success({ message: 'Voice record deleted successfully' });
            } catch (error) {
                notification.error({ message: 'An error occurred while deleting the record...' });
            }
        }
    };

    const showDeleteConfirmation = (record) => {
        setRecordToDelete(record);
        setDeleteConfirmationVisible(true);
    };

    const handleCancelDelete = () => {
        setDeleteConfirmationVisible(false);
        setRecordToDelete(null);
    };

    const handlePlayPause = (url) => {
        setPlayingUrl(prevUrl => (prevUrl === url ? null : url));
    };

    useEffect(() => {
        fetchVoiceRecordsData(null, true);
    }, []);

    const handleScroll = useCallback((e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target.scrollingElement;
        if (scrollHeight - scrollTop === clientHeight && nextToken && !loading) {
            fetchVoiceRecordsData(nextToken);
        }
    }, [nextToken, loading]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const handleRefresh = () => {
        fetchVoiceRecordsData(null, true);
    };

    return (
        <div style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
            {error && <Typography.Text type="danger" style={{ display: 'block', textAlign: 'center', marginBottom: '20px' }}>{error}</Typography.Text>}

            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Button
                    icon={<ReloadOutlined />}
                    onClick={handleRefresh}
                    style={{
                        backgroundColor: '#1890ff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '10px 20px',
                    }}
                >
                    Refresh
                </Button>
            </div>

            <List
                dataSource={voiceRecords}
                renderItem={record => (
                    <List.Item
                        key={record.url}
                        style={{
                            padding: '10px',
                            borderRadius: '10px',
                            backgroundColor: '#fff',
                            marginBottom: '15px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            border: '1px solid #e8e8e8',
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            <Typography.Text style={{ fontWeight: 'bold' }}>{record.uploaded_by}</Typography.Text>
                            <div style={{ fontSize: '12px', color: '#999' }}>{new Date(record.last_modified).toLocaleDateString()}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                icon={playingUrl === record.url ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                                onClick={() => handlePlayPause(record.url)}
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    color: '#1890ff',
                                    marginRight: '10px',
                                }}
                            />
                            <Button
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => showDeleteConfirmation(record)}
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    color: '#f5222d',
                                }}
                            />
                        </div>
                        {playingUrl === record.url && (
                            <ReactAudioPlayer
                                src={record.url}
                                autoPlay
                                controls
                                style={{ width: '100%', marginTop: '10px' }}
                                onError={() => {
                                    notification.error({ message: 'Error loading audio file. Please check the file URL and format.' });
                                }}
                            />
                        )}
                    </List.Item>
                )}
            />

            {loading && <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />}

            <Modal
                title="Are you sure you want to delete the audio recording?"
                open={deleteConfirmationVisible}
                onOk={handleDelete}
                onCancel={handleCancelDelete}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>This action cannot be undone!</p>
            </Modal>
        </div>
    );
};

export default VoiceViewComponent;

import axios from 'axios';
import {deleteFile, getPhoto} from "../../../config/api-config";

export const fetchPhotos = async (lastKey = null, limit = 5) => {
    try {
        const response = await axios.get(getPhoto, {
            params: {
                last_key: lastKey,
                limit: limit,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Fotoğraflar çekilirken bir hata oluştu:', error);
        throw error;
    }
};


export const deleteFileApi = async (objKey) => {
    try {
        const response = await axios.delete(deleteFile, {
            data: { objKey },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Fotoğraf silinirken bir hata oluştu:', error);
        throw error;
    }
};

import axios from 'axios';
import {
    downloadMedia, getNotesCount,
    getProfileData, updateInstructions,
    updatePassword,
    updateProfilePicture, updateQRStatus,
    updateUsername, updateWelcomeMessage
} from "../../../config/api-config";

const getAuthToken = () => {
    return localStorage.getItem('authToken');
};

export const fetchUserInfo = async () => {
    try {
        const response = await axios.get(getProfileData, {
            headers: {
                Authorization: `Bearer ${getAuthToken()}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const changePassword = async (oldPassword, newPassword) => {
    try {
        const response = await axios.post(
            updatePassword,
            {
                old_password: oldPassword,
                new_password: newPassword,
            },
            {
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error changing password:', error);
        throw error;
    }
};

export const updateUsernameApi = async (new_username) => {
    try {
        const response = await axios.put(
            `${updateUsername}?new_username=${encodeURIComponent(new_username)}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const uploadProfilePicture = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(updateProfilePicture, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${getAuthToken()}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Failed to upload profile picture:', error);
        throw error;
    }
};

export const downloadContent = async (mediaType) => {
    try {
        const response = await axios.post(
            downloadMedia+`${mediaType}/`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateWelcomeMessageApi = async (welcome_message) => {
    try {
        const response = await axios.put(
            updateWelcomeMessage,
            { welcome_message },
            {
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateInstructionsApi = async (instructions) => {
    try {
        const response = await axios.put(
            updateInstructions,
            { instructions },
            {
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateQRStatusApi = async (is_lock) => {
    try {
        const response = await axios.put(
            updateQRStatus,
            { "is_lock":is_lock },
            {
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchNotesCount = async () => {
    try {
        const response = await axios.get(getNotesCount, {
            headers: {
                Authorization: `Bearer ${getAuthToken()}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

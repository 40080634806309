import React, { useState } from 'react';
import { Upload, Button, message, Progress, Typography, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import translations from "../../../config/translation/translation-config";
import { videoUploadApi } from "../../../api/services/qr/video/video-upload-call";

const { Text } = Typography;

const VideoUpload = ({ language, objectStorage }) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fullName, setFullName] = useState("");

    const beforeUpload = (file) => {
        const isVideo = file.type.startsWith('video/');
        if (!isVideo) {
            message.error(translations[language].onlyVideoFilesAllowed);
            return Upload.LIST_IGNORE;
        }

        if (file.size > 100 * 1024 * 1024) {
            message.error(`${translations[language].fileTooLarge} (100MB max)`);
            return Upload.LIST_IGNORE;
        }

        setFileList([file]);
        return false;
    };

    const handleChange = (info) => {
        setFileList(info.fileList.slice(-1));
    };

    const handleUpload = async () => {
        if (fileList.length === 0) {
            message.error(translations[language].noFileSelected);
            return;
        }

        setUploading(true);
        const file = fileList[0].originFileObj;
        const formData = new FormData();
        formData.append('files', file);
        formData.append('path_id', objectStorage);
        formData.append('username', fullName);

        try {
            await videoUploadApi(formData, {
                onUploadProgress: (progressEvent) => {
                    const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percent);
                },
            });
            message.success(translations[language].videosUploadedSuccessfully);
            setFileList([]);
        } catch (error) {
            message.error(translations[language].videoUploadFailed);
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    return (
        <div>
            <Input
                placeholder="Please write your full name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                style={{ marginBottom: 16 }}
            />
            <Text type="secondary">
                {translations[language].maxOneVideo} - {translations[language].startUploadVideoInstructions}
            </Text>
            <Upload
                accept="video/*"
                beforeUpload={beforeUpload}
                fileList={fileList}
                onChange={handleChange}
                showUploadList={{ showRemoveIcon: true }}
            >
                <Button icon={<UploadOutlined />} disabled={uploading}>
                    {translations[language].uploadVideos}
                </Button>
            </Upload>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0 || uploading || fullName.trim() === ""}
                loading={uploading}
                style={{ marginTop: 16 }}
            >
                {uploading ? translations[language].uploading : translations[language].startUpload}
            </Button>
            {uploading && <Progress percent={uploadProgress} />}
        </div>
    );
};

export default VideoUpload;

import axios from 'axios';
import {noteUpload} from "../../../config/api-config";

export const noteUploadApi = async (noteData) => {
    try {
        const response = await axios.post(noteUpload, noteData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

import React, { useState, useEffect, useRef } from 'react';
import { List, Card, Button, Modal, Spin, Typography, Switch, notification } from 'antd';
import { DeleteOutlined, DownloadOutlined, AppstoreOutlined, UnorderedListOutlined, ReloadOutlined } from '@ant-design/icons';
import {fetchPhotos, deleteFile, deleteFileApi} from "../../../api/services/user-main/photo/user-photo-service";

const Photos = () => {
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [nextToken, setNextToken] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [viewMode, setViewMode] = useState('list');
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [photoToDelete, setPhotoToDelete] = useState(null);

    const observer = useRef();

    const fetchPhotosData = async (lastKey = null, reset = false) => {
        setLoading(true);
        try {
            const data = await fetchPhotos(lastKey);
            setPhotos(prevPhotos => {
                const uniquePhotos = data.newFiles.filter(
                    newPhoto => !prevPhotos.some(photo => photo.url === newPhoto.url)
                );
                return reset ? uniquePhotos : [...prevPhotos, ...uniquePhotos];
            });
            setNextToken(data.nextKey);
        } catch (error) {
            setError('An error occurred while photos are loading...');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPhotosData(null, true);
    }, []);

    useEffect(() => {
        if (loading || !nextToken) return;

        const handleIntersection = (entries) => {
            if (entries[0].isIntersecting) {
                fetchPhotosData(nextToken);
            }
        };

        observer.current = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        });

        const target = document.querySelector('#load-more-trigger');
        if (target) {
            observer.current.observe(target);
        }

        return () => {
            if (observer.current && target) {
                observer.current.unobserve(target);
            }
        };
    }, [nextToken, loading]);

    const handleDelete = async () => {
        if (photoToDelete) {
            try {
                await deleteFileApi(photoToDelete);
                setPhotos(prevPhotos => prevPhotos.filter(photo => photo.url !== photoToDelete));
                setDeleteConfirmationOpen(false);
                setPhotoToDelete(null);
            } catch (error) {
                notification.error({ message: 'An error occurred while deleting photo...' });
            }
        }
    };

    const handleDownload = (url) => {
        window.open(url, '_blank');
    };

    const handlePhotoClick = (photo) => {
        setSelectedPhoto(photo);
    };

    const handleCloseDialog = () => {
        setSelectedPhoto(null);
    };

    const handleDeleteClick = (url) => {
        setPhotoToDelete(url);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmationOpen(false);
        setPhotoToDelete(null);
    };

    const handleViewModeChange = (checked) => {
        setViewMode(checked ? 'grid' : 'list');
    };

    const handleRefresh = () => {
        fetchPhotosData(null, true);
    };

    return (
        <div style={{ padding: '10px', height: 'calc(100vh - 64px)', overflowY: 'auto' }}>
            {error && <Typography.Text type="danger">{error}</Typography.Text>}

            <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
                <Button icon={<ReloadOutlined />} onClick={handleRefresh}>Refresh</Button>
                <Switch
                    checkedChildren={<AppstoreOutlined />}
                    unCheckedChildren={<UnorderedListOutlined />}
                    onChange={handleViewModeChange}
                    checked={viewMode === 'grid'}
                />
            </div>

            <List
                grid={viewMode === 'grid' ? { gutter: 16, column: 2 } : undefined}
                dataSource={photos}
                renderItem={photo => (
                    <List.Item style={viewMode === 'list' ? { width: '100%' } : {}}>
                        <Card
                            style={{ overflow: 'hidden' }}
                            cover={
                                <div style={viewMode === 'grid' ? { position: 'relative', paddingBottom: '100%' } : {}}>
                                    <img
                                        alt={photo.uploaded_by}
                                        src={photo.url}
                                        style={viewMode === 'grid' ? { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' } : { width: '100%', objectFit: 'cover' }}
                                        onClick={() => handlePhotoClick(photo)}
                                    />
                                </div>
                            }
                        >
                            <div style={{ padding: '10px', textAlign: 'center' }}>
                                <Typography.Text>{photo.uploaded_by}</Typography.Text>
                                <br />
                                <Typography.Text type="secondary">{new Date(photo.last_modified).toLocaleString()}</Typography.Text>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                                <Button
                                    icon={<DownloadOutlined />}
                                    onClick={() => handleDownload(photo.url)}
                                />
                                <Button
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => handleDeleteClick(photo.url)}
                                />
                            </div>
                        </Card>
                    </List.Item>
                )}
            />

            {loading && <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />}

            <div id="load-more-trigger" style={{ height: '1px' }}></div>

            <Modal
                visible={!!selectedPhoto}
                footer={null}
                onCancel={handleCloseDialog}
                width={800}
            >
                {selectedPhoto && (
                    <img
                        src={selectedPhoto.url}
                        alt={selectedPhoto.uploaded_by}
                        style={{ width: '100%', height: 'auto' }}
                    />
                )}
            </Modal>

            <Modal
                visible={deleteConfirmationOpen}
                title="Are you sure you want to delete?"
                onCancel={handleDeleteCancel}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>Cancel</Button>,
                    <Button key="delete" type="primary" danger onClick={handleDelete}>Delete</Button>
                ]}
            >
                <p>This operation can not be undone!</p>
            </Modal>
        </div>
    );
};

export default Photos;

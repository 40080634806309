import React, { useState, useEffect } from 'react';
import {
    Box,
    Avatar,
    Button,
    TextField,
    Typography,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import './ProfilePage.css';
import {
    changePassword,
    fetchUserInfo, updateUsernameApi,
    uploadProfilePicture,
} from '../../../../api/services/user-main/profile/user-profile';

const ProfilePage = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editUsername, setEditUsername] = useState(false);
    const [username, setUsername] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);
    const [uploading, setUploading] = useState(false); // Yeni loading state'i

    useEffect(() => {
        const loadUserInfo = async () => {
            try {
                const data = await fetchUserInfo();
                setUserData(data);
                setUsername(data.username);
            } catch (error) {
                console.error('Failed to load user data');
            } finally {
                setLoading(false);
            }
        };
        loadUserInfo();
    }, []);

    const handleUsernameUpdate = async () => {
        try {
            await updateUsernameApi(username);
            setEditUsername(false);
        } catch (error) {
            console.error('Failed to update username');
        }
    };

    const handlePasswordChange = async () => {
        try {
            await changePassword(oldPassword, newPassword);
            setOldPassword('');
            setNewPassword('');
            alert('Password changed successfully');
        } catch (error) {
            console.error('Failed to change password');
        }
    };

    const handleProfilePictureChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploading(true); // Yükleme başlıyor
            try {
                const formData = new FormData();
                formData.append('file', file);

                await uploadProfilePicture(file);
                setProfilePicture(URL.createObjectURL(file));
            } catch (error) {
                console.error('Failed to upload profile picture:', error);
            } finally {
                setUploading(false); // Yükleme bitiyor
            }
        }
    };

    return (
        <Box className={`profile-page-container ${uploading ? 'blurry' : ''}`}>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <Box className="profile-header">
                        <Box className="profile-avatar-container">
                            <label htmlFor="upload-button">
                                <input
                                    id="upload-button"
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleProfilePictureChange}
                                />
                                <Avatar
                                    src={profilePicture || userData?.profile_picture}
                                    alt="Profile Picture"
                                    className="profile-avatar"
                                    sx={{ width: 180, height: 180, cursor: 'pointer' }}
                                />
                            </label>
                            {uploading && (
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        position: 'absolute',
                                        top: 'calc(50% - 20px)',
                                        left: 'calc(50% - 20px)',
                                    }}
                                />
                            )}
                        </Box>
                        <Box className="profile-info-container">
                            <Box className="profile-info-row">
                                <Typography className="profile-email">{userData?.email}</Typography>
                            </Box>
                            <Box className="profile-info-row">
                                <Box className="profile-username-container">
                                    {editUsername ? (
                                        <>
                                            <TextField
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                            />
                                            <Button
                                                onClick={handleUsernameUpdate}
                                                className="save-button"
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                onClick={() => setEditUsername(false)}
                                                className="cancel-button"
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Typography className="profile-username">
                                                {username}
                                            </Typography>
                                            <IconButton
                                                onClick={() => setEditUsername(true)}
                                                className="edit-icon"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="profile-section">
                        <Typography variant="h6" className="section-title">
                            Change Password
                        </Typography>
                        <TextField
                            label="Old Password"
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            className="update-button"
                            onClick={handlePasswordChange}
                        >
                            Update Password
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProfilePage;

import axios from "axios";
import {photoUpload} from "../../../config/api-config";

export const photoUploadApi = async (formData) => {
    try {
        const response = await axios.post(photoUpload, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

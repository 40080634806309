import React, { useState } from 'react';
import {
    TextField,
    Button,
    Container,
    Box,
    Typography,
    CssBaseline,
    IconButton,
    InputAdornment
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginApiCall } from "../../../api/services/user-main/login/login-call";

import logo from '../../../assets/logo/login-box.png';
import backgroundImage from '../../../assets/logo/login-background.png';

const LoginScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await loginApiCall({ username: email, password });
            localStorage.setItem('authToken', response.access_token);
            navigate('/user-main');
        } catch (error) {
            setError('Authentication error, please write correct credentials!');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: theme.spacing(2),
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'repeat',
                backgroundPosition: 'center',
                minHeight: '100vh',
            }}
        >
            <CssBaseline />

            <Box sx={{ mb: 3 }}>
                <img src={logo} alt="Logo" style={{ maxWidth: '90%', height: 'auto' }} />
            </Box>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                {error && (
                    <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                        {error}
                    </Typography>
                )}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Login
                </Button>
            </Box>

            <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Typography variant="body2" color="textSecondary">
                    Phone: +38975561762
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    &copy; 2024 dp solutions. All Rights Reserved ®
                    dpsolutions.mk
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    memory-box.co
                </Typography>
            </Box>
        </Container>
    );
};

export default LoginScreen;

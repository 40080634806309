import axios from "axios";
import {deleteNotesApi, getNotes} from "../../../config/api-config";

export const fetchNotes = async (lastKey = null, limit = 5) => {
    try {
        const response = await axios.get(getNotes, {
            params: {
                last_key: lastKey,
                limit: limit,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Fotoğraflar çekilirken bir hata oluştu:', error);
        throw error;
    }
};

export const deleteNotes = async (objectId) => {
    try {
        const response = await axios.delete(deleteNotesApi, {
            data: { objectId },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Fotoğraf silinirken bir hata oluştu:', error);
        throw error;
    }
};


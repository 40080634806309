import axios from 'axios';
import {initialRequest} from "../config/api-config";

export const fetchQRCodeData = async (qrId) => {
    try {
        const response = await axios.get(`${initialRequest}${qrId}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Switch,
    IconButton,
    Paper,
    CircularProgress,
    Tooltip,
    Snackbar,
    Grid,
} from '@mui/material';
import { FaCamera, FaVideo, FaMicrophone, FaStickyNote } from 'react-icons/fa'; // FontAwesome ikonları ekliyoruz
import { Save as SaveIcon, Edit as EditIcon, Cancel as CancelIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import './EventsPage.css';
import {
    downloadContent, fetchNotesCount,
    fetchUserInfo,
    updateInstructionsApi,
    updateQRStatusApi,
    updateWelcomeMessageApi,
} from "../../../../api/services/user-main/profile/user-profile";

const EventsPage = () => {
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [instructions, setInstructions] = useState('');
    const [qrStatus, setQrStatus] = useState(undefined);
    const [totals, setTotals] = useState({ photos: 0, videos: 0, voiceRecords: 0, notes: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState({ welcome: false, instructions: false });
    const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

    const loadEventData = async () => {
        try {
            const userInfo = await fetchUserInfo();
            const notesCount = await fetchNotesCount();

            setWelcomeMessage(userInfo.welcomeMessage || '');
            setInstructions(userInfo.instructions || '');
            setQrStatus(userInfo.is_lock);
            setTotals({
                photos: notesCount.photos,
                videos: notesCount.videos,
                voiceRecords: notesCount.voices,
                notes: notesCount.notes
            });
        } catch (error) {
            setError(error.message || 'Failed to load event data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadEventData();
    }, []);

    const handleWelcomeMessageSave = async () => {
        try {
            await updateWelcomeMessageApi(welcomeMessage);
            setNotification({ open: true, message: 'Welcome message updated successfully', severity: 'success' });
            setEditMode({ ...editMode, welcome: false });
        } catch (error) {
            setNotification({ open: true, message: 'Failed to update welcome message', severity: 'error' });
        }
    };

    const handleInstructionsSave = async () => {
        try {
            await updateInstructionsApi(instructions);
            setNotification({ open: true, message: 'Instructions updated successfully', severity: 'success' });
            setEditMode({ ...editMode, instructions: false });
        } catch (error) {
            setNotification({ open: true, message: 'Failed to update instructions', severity: 'error' });
        }
    };

    const handleCancelEdit = (field) => {
        setEditMode({ ...editMode, [field]: false });
    };

    const handleQRStatusChange = async (checked) => {
        try {
            setQrStatus(checked);
            await updateQRStatusApi(checked);
            setNotification({ open: true, message: 'QR status updated successfully', severity: 'success' });
        } catch (error) {
            setNotification({ open: true, message: 'Failed to update QR status', severity: 'error' });
        }
    };

    const handleDownload = async (mediaType) => {
        try {
            await downloadContent(mediaType);
            setNotification({ open: true, message: 'Download link will be sent to your email', severity: 'success' });
        } catch (error) {
            setNotification({ open: true, message: `Failed to download ${mediaType}`, severity: 'error' });
        }
    };

    const handleCloseNotification = () => {
        setNotification({ ...notification, open: false });
    };

    const handleRefresh = () => {
        setLoading(true);
        loadEventData();
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box className="events-page-container">
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Tooltip title="Refresh Data">
                    <IconButton onClick={handleRefresh} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <Paper className="welcome-section">
                <Typography variant="h6">Event Information</Typography>
                <Box className="input-group">
                    <TextField
                        label="Welcome Message"
                        value={welcomeMessage}
                        onChange={(e) => setWelcomeMessage(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        disabled={!editMode.welcome}
                    />
                    <Box className="edit-buttons">
                        {editMode.welcome ? (
                            <>
                                <Tooltip title="Save Welcome Message">
                                    <IconButton onClick={handleWelcomeMessageSave} color="primary">
                                        <SaveIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <IconButton onClick={() => handleCancelEdit('welcome')} color="secondary">
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <Tooltip title="Edit Welcome Message">
                                <IconButton onClick={() => setEditMode({ ...editMode, welcome: true })} color="primary">
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>

                <Box className="input-group">
                    <TextField
                        label="Instructions"
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        disabled={!editMode.instructions}
                    />
                    <Box className="edit-buttons">
                        {editMode.instructions ? (
                            <>
                                <Tooltip title="Save Instructions">
                                    <IconButton onClick={handleInstructionsSave} color="primary">
                                        <SaveIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <IconButton onClick={() => handleCancelEdit('instructions')} color="secondary">
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <Tooltip title="Edit Instructions">
                                <IconButton onClick={() => setEditMode({ ...editMode, instructions: true })} color="primary">
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" marginY="20px">
                    <Typography>QR Status</Typography>
                    <Switch
                        checked={qrStatus}
                        onChange={(e) => handleQRStatusChange(e.target.checked)}
                        color="primary"
                    />
                </Box>
            </Paper>

            <Paper className="contents-container">
                <Typography variant="h6">Content Summary</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <FaCamera fontSize="24px" color="#e91e63" />
                            <Typography variant="body1" marginLeft="10px">Photos: {totals.photos}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <FaVideo fontSize="24px" color="#2196f3" />
                            <Typography variant="body1" marginLeft="10px">Videos: {totals.videos}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <FaMicrophone fontSize="24px" color="#ff9800" />
                            <Typography variant="body1" marginLeft="10px">Voice Records: {totals.voiceRecords}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <FaStickyNote fontSize="24px" color="#4caf50" />
                            <Typography variant="body1" marginLeft="10px">Notes: {totals.notes}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <Paper className="download-container">
                <Typography variant="h6">Download Content</Typography>
                <Box display="flex" flexDirection="column" marginTop="15px">
                    <Box display="flex" alignItems="center" className="download-item" onClick={() => handleDownload('photos')}>
                        <FaCamera style={{ color: '#1976d2' }} />
                        <Typography variant="body1" marginLeft="10px" style={{ color: '#1976d2' }}>Download All Photos</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className="download-item" onClick={() => handleDownload('videos')}>
                        <FaVideo style={{ color: '#1976d2' }} />
                        <Typography variant="body1" marginLeft="10px" style={{ color: '#1976d2' }}>Download All Videos</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className="download-item" onClick={() => handleDownload('voices')}>
                        <FaMicrophone style={{ color: '#1976d2' }} />
                        <Typography variant="body1" marginLeft="10px" style={{ color: '#1976d2' }}>Download All Voice Records</Typography>
                    </Box>
                </Box>
            </Paper>

            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
                message={notification.message}
                severity={notification.severity}
            />
        </Box>
    );
};

export default EventsPage;

import axios from "axios";
import {getPhoto, getVoices} from "../../../config/api-config";

export const fetchVoices = async (lastKey = null, limit = 5) => {
    try {
        const response = await axios.get(getVoices, {
            params: {
                last_key: lastKey,
                limit: limit,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Fotoğraflar çekilirken bir hata oluştu:', error);
        throw error;
    }
};
